import React,{lazy,Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { AuthProvider } from './Ikas/AuthContext';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
const App=lazy(()=>import('./App'))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <Suspense>
      <AuthProvider>
        <App />
      </AuthProvider>
      </Suspense>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
