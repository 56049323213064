import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [tokenType, setTokenType] = useState(null);
    const [expiresIn, setExpiresIn] = useState(null);

    return (
        <AuthContext.Provider value={{ accessToken, setAccessToken, tokenType, setTokenType, expiresIn, setExpiresIn }}>
            {children}
        </AuthContext.Provider>
    );
};